import projects from './projects'
import experiences from './experiences'
import technologies from './technologies'

const data = {
  projects,
  experiences,
  technologies,
}

export default data
